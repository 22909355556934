import React, { useState, useEffect } from 'react';
import { WithDefaultLayout } from '../components/DefautLayout';
import { Title } from '../components/Title';
import { Page } from '../types/Page';
import { useRouter } from 'next/router';
import { useAuthorizedAxios } from '../functions/useAuthorizedAxios';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-bootstrap';
import axios from 'axios';

interface LacakPesanan {
    noPesanan: string
}

const schema = Joi.object<LacakPesanan>({
    noPesanan: Joi.string().required().pattern(/^\w+(?:\s+\w+)*$/).messages({
        'any.required': 'Nomor Paket tidak ditemukan',
        'string.empty': 'Silahkan masukkan nomor pesanan',
        'string.pattern.base': 'Silahkan masukkan nomor pesanan',
    })
});

const IndexPage: Page = () => {
    const { register, handleSubmit, formState: { errors }, watch } = useForm<LacakPesanan>({
        resolver: joiResolver(schema)
    })
    const formData = watch();
    const [user, setUser] = useState('');
    const axiosClient = useAuthorizedAxios();
    useEffect(() => {
        const data = async () => {
            const response = await axiosClient.get(`/api/main-be/api/v1/Auth/user-profile`);
            if (response.data && response.status == 200) {
                setUser(response.data.name);
            }
        }
        data();
    }, [axiosClient])

    const router = useRouter();

    const [noDataMessage, setNodataMessage] = useState<boolean>(true);

    useEffect(()=>{
        if(!formData.noPesanan){
            setNodataMessage(true);
        }
    }, [formData]);

    const redirectToDetailResiPage = async (value: LacakPesanan) => {
        const client = await axios.get<boolean>(`/api/main-be/api/v1/Order/check-order?detailTransactionId=${value.noPesanan}`);
        setNodataMessage(client.data);
        if (client.data === true) {
            router.push(`/User/detailpesanansaya/${value.noPesanan}`);
        }
    }

    const redirectForm = () => {
        if (Object.keys(user).length === 0) {
            return '/form'
        } else {
            return '/User/Form'
        }
    };

    const Lacak = () => {
        return (
            <div className='px-5 w-100 py-5' style={{ minWidth: '33.3%' }}>
                <div className='d-flex flex-column justify-content-center'>
                    <h4 className='text-white mx-2'>Lacak Paket</h4>
                    <form onSubmit={handleSubmit(redirectToDetailResiPage)} className='d-flex flex-xl-row flex-md-column'>
                        <input id="label" type="text" className="form-control mx-2 mb-2 lacakPaketInputIndex" placeholder="Masukan Nomor Pesanan" {...register('noPesanan')}></input>
                        <button id="buttonhome" type="submit" className="btn text-danger btn-warning fw-bold mx-2 mb-2" style={{ maxWidth: '10rem' }}>Lacak</button>
                    </form>
                    {errors.noPesanan?.message ? <p className="text-break text-white ms-2">{errors.noPesanan?.message}</p> : <></>}
                    {!noDataMessage && formData.noPesanan.length !== 0 ? <p className="text-break text-white ms-2">Nomor Paket tidak ditemukan</p> : <></>}
                </div>
            </div>
        );
    }

    const CekTarif = () => {
        return (
            <div id='borderCombine' className='w-100 py-5' style={{ minWidth: '33.3%' }}>
                <h4 className='text-white mx-2 text-center'>Cek Tarif Pengiriman</h4>
                <div className='d-flex flex-row justify-content-center'>
                    <NavLink id="buttonhome" href='/cektarif' className="btn text-danger btn-warning fw-bold px-5 w-auto mt-1" style={{ minWidth: '10rem' }}>Cek</NavLink>
                </div>
            </div>
        );
    }

    const JemputPaket = () => {
        return (
            <div className='w-100 py-5' style={{ minWidth: '33.3%' }}>
                <h4 className='text-white mx-2 text-center'>Jemput Paket</h4>
                <div className='d-flex flex-row justify-content-center'>
                    <NavLink id="buttonhome" href={redirectForm()} className="btn text-danger btn-warning fw-bold px-5 w-auto mt-1">Jemput</NavLink>
                </div>
            </div>
        );
    }

    const Combines = () => {
        return (
            <div id='red' className='d-flex justify-content-around combinesMobileView align-items-stretch'>
                {Lacak()}
                {CekTarif()}
                {JemputPaket()}
            </div>
        );
    }

    const TentangPerusahaan = () => {
        return (
            <div id='TentangPerusahaan' className='d-flex my-5 me-3'>
                <div id='homeimg col-md-7 col-lg-7 col-xl-7'>
                    <img className="px-5 img rounded float-start" src="truck.jpg" alt="indexpict"></img>
                </div>
                <div className='mx-2 col-md-5 col-lg-5 col-xl-5'>
                    <h3 id="textred" className='fw-bold mt-2 tentangperusahaanMobile'>____ Tentang Perusahaan</h3>
                    <h3 className='fw-bold mt-4'>
                        Perusahaan Logistik Era Digital
                    </h3>
                    <h3 className='fw-bold mt-2'>
                        Aman & Terpercaya
                    </h3>
                    <p id="pHome" className='text-break text-wrap text-start mt-3 col-10'>Dengan perkembangan teknologi Era 4.0 di Indonesia, semua sektor
                        bisnis telah menggunakan bisnis digital. PT. Petir Logistik Ekspres Indonesia yang
                        sudah berpengalaman dalam segmen Korporasi & Eceran bersinergi dengan
                        PT. Accelist Lentera Indonesia.
                    </p>
                    <p id="pHome" className='text-break text-wrap col-10'>
                        Dengan sinergi tersebut kami sukses meluncurkan situs pengiriman online
                        sehingga masyarakat luas dapat mengakses sistem dengan mudah, cepat dan terjamin sampai
                        tujuan, dengan biaya ongkos kirim sangat terjangkau.
                    </p>

                    <div className='d-flex mt-5'>
                        <img id="homeicon" className="px-3 pb-3" src="globehome.jpg" alt="iconglobe"></img>
                        <div>
                            <h5 className='fw-bold'> Pengiriman Internasional</h5>
                            <p id="pHome" className='text-muted'>Dapat melayanani pengiriman dengan tujuan ke luar negeri.</p>
                        </div>
                    </div><br></br>

                    <div className='d-flex'>
                        <img id="homeicon" className="px-3 pb-3" src="pinhome.jpg" alt="icongpin"></img>
                        <div>
                            <h5 className='fw-bold'>Pengiriman Domestik</h5>
                            <p id="pHome" className='text-muted'>Dapat melayanani pengiriman dengan tujuan dalam negeri.</p>
                        </div>
                    </div><br></br>
                    {/* <button id='red' type="submit" className="btn text-light fw-bold">Lihat Semua</button> */}
                </div>

            </div>
        );
    }


    const Produk = () => {
        return (
            <div id='backgroundProduk'>
                <div id='produkMobileView' className='d-flex justify-content-between me-5 pt-5 pb-5'>
                    <div className='px-5'>
                        <h3 id="textred" className='fw-bold'>_____ Produk & Layanan</h3><br></br>
                        <h3 className='fw-bold'> Komitmen Untuk Selalu
                            <br /> Memberikan Pelayanan Terbaik
                            <br /> Bagi Pelanggan Kami
                            <br /></h3>
                        {/* <button id='ButtonLihat' type="submit" className="btn text-light fw-bold">Lihat Semua</button> */}
                    </div>

                    <div id='cardhover' className="card cardMobileView" >
                        <img src="kapal.jpg" className="card-img-top" alt="..."></img>
                        <div className="card-body">
                            <h5 className="card-title">Regular</h5>
                            <p className="card-text ">Layanan 2-3 hari Sampai
                                <br></br>Pengiriman paket dengan jaringan ke seluruh Indonesia. Pilihan pengiriman yang dapat diandalkan.</p>
                            {/* <a href="#" id='button' className=""> 
                                <span id='icon'> <FontAwesomeIcon  className='px-1 fa-xl' icon={faArrowRight}></FontAwesomeIcon> </span>
                                <span id='text'> Lihat Detail <FontAwesomeIcon  className='px-1 fa-xl' icon={faArrowRight}></FontAwesomeIcon></span>
                                </a> */}
                        </div>
                    </div>

                    <div id='cardhover' className="card cardMobileView" >
                        <img src="pesawat.jpg" className="card-img-top" alt="..."></img>
                        <div className="card-body">
                            <h5 className="card-title">Ekspress</h5>
                            <p className="card-text">Layanan Besok Sampai<br></br>
                                Pengiriman Paket besok sampai, solusi untuk kamu yang tidak ingin menunggu lama.</p>
                            {/* <a href="#" id='button' className=""> 
                                <span id='icon'> <FontAwesomeIcon  className='px-1 fa-xl' icon={faArrowRight}></FontAwesomeIcon> </span>
                                <span id='text'> Lihat Detail <FontAwesomeIcon  className='px-1 fa-xl' icon={faArrowRight}></FontAwesomeIcon></span>
                                </a> */}
                        </div>
                    </div>

                </div>

            </div>
        );
    }

    const Mitra = () => {
        return (
            <div className='pt-5 my-2' id='mitraNav'>
                <h3 id="textred" className='fw-bold d-flex justify-content-center '>______ Mitra Kami ______</h3>
                <img className='mt-3' src={'Mitra.jpg'} width="100%" alt="image1" />
            </div>
        );
    }

    const Render = () => {
        return (
            <div>
                {Combines()}
                {TentangPerusahaan()}
                {Produk()}
                {Mitra()}
            </div>
        );
    }
    return (
        <div>
            <Title>Home</Title>
            <div className='d-flex flex-column' id='berandaHalaman'>
                <img alt="indexpict" className=" position-relative" src="index-pict.jpg"></img>
            </div>
            {Render()}
        </div>
    );
}

IndexPage.layout = WithDefaultLayout;
export default IndexPage;
